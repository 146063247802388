<template>
    <div>
        <div class="div-crud-sistema" style="overflow-x: auto;">
            <div class="row">
                <div class="col" style="text-align: center;font-weight: bold;">
                    LISTAGEM DE WEBHOOKS NA LIXEIRA (Webhooks recebidos na qual a oferta não existe mais no ERP)
                </div>
            </div>            
            
            <div class="row" >
                <div class="col">
                    <table class="table table-hover table-sm" style="white-space: normal;border-top: 0px;width:100%;">
                        <thead>
                            <tr style="background-color: #1c4d5a;color:white;">
                                <!-- <th style="text-align: center;">
                                    Ações
                                </th> -->
                                <th style="text-align: center;max-width: 120px;width: 50px;">
                                    WebHook
                                </th>
                                <th style="text-align: center;text-overflow: ellipsis;width:10px;overflow: hidden;white-space: nowrap;">
                                    ID CRM
                                </th>
                                <th style="text-align: center;max-width: 250px;width: 250px;">
                                    Nome
                                </th>
                                <th style="text-align: center;max-width: 120px;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 100px;">
                                    Cod. Oferta CRM
                                </th>
                                <th style="text-align: center;width: 45px;">
                                    Sinc.
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Data
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Atualização
                                </th>
                                <th style="text-align: center;width: 50px;">
                                    Origem
                                </th>
                                <th style="text-align: center;width: 100px;">
                                    Tipo
                                </th>
                                
                            </tr>
                        </thead>
                        <tbody v-if="webhooks && webhooks.length>0">
                            <template v-for="aluno_captado,item in webhooks" >
                                <tr :key="item">
                                    <!-- <td style="text-align: center;white-space: nowrap;width:50px!important;max-width:50px!important;" class="align-middle">
                                        
                                    </td> -->
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.id }}
                                    </td>
                                    <td class="align-middle" style="text-align: center;font-weight: bold;">
                                        {{ aluno_captado.contatoid }}
                                    </td>
                                    <td class="align-middle" style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:350px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.nomeinscrito }}
                                    </td>
                                    
                                    <td class="align-middle" style="text-align: center;font-weight: bold;text-overflow: ellipsis;max-width:120px;overflow: hidden;white-space: nowrap;">
                                        {{ aluno_captado.codigooferta }}
                                    </td>
                                    
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b-icon v-if="aluno_captado.processado == 1" icon="check-lg" font-scale="1.3" style="color:green"></b-icon>
                                        <b-icon v-else icon="x-lg" font-scale="1.3" style="color:red"></b-icon>
                                    </td>
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        {{  daraHoraBrasileiro(aluno_captado.created_at) }}
                                    </td>
                                    <td style="text-align: center;white-space: nowrap;" class="align-middle">
                                        {{  daraHoraBrasileiro(aluno_captado.updated_at) }}
                                    </td>
                                    <td style="white-space: normal;text-align: center;" class="align-middle">
                                        <b>{{ aluno_captado.origem_inclusao }}</b>
                                    </td>
                                    <td style="text-align: left;font-weight: bold;text-overflow: ellipsis;max-width:125px;overflow: hidden;white-space: nowrap;" class="align-middle">
                                        <b>{{ ( aluno_captado.tipoeventoaviso=='c' ? "Insc. Confirmada" : ( aluno_captado.tipoeventoaviso=='a' ? "Contrato Assinado" : ( aluno_captado.tipoeventoaviso=='m' ? "Matriculado":( aluno_captado.tipoeventoaviso=='v' ? "Envia Valor CRM":( aluno_captado.tipoeventoaviso=='f' ? "Atualização Cadastral":"Outros" ) ) ) ) ) }}</b>
                                    </td>
                                </tr>

                            </template>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td colspan="9" style="text-align: center;font-weight: bold;">
                                    Não existem dados para exibir
                                </td>
                            </tr>
                        </tbody>
                        <!-- <tfoot>
                            <tr>
                                <td colspan="12" style="text-align: center;background-color: #1c4d5a;color:white;" class="align-middle">
                                    <paginate class="padding text-center">
                                        <li v-for=" link, key in lista_web_hook.links" :key="key" :class="(link.active ? 'page-item active':(link.url ? 'page-item':'page-item disabled'))" style="cursor:pointer;">
                                            <a class="page-link" tabindex="-1"  style="border-color: #fff!important;">{{ (key==0 ? "<<" :(key==lista_web_hook.links.length-1 ? ">>": link.label ) ) }}        </a>
                                        </li>
                                    </paginate>
                                </td>
                            </tr>
                        </tfoot> -->
                    </table>
                </div>
            </div>
        </div>
        
        <!-- {{ webhooks }} -->
    </div>
</template>

<script>
export default {
    props:['usuarioLogado','webhooks'],
    data(){
        return{
            captados_webhook:[],
            lista_web_hook:[],
            web_hook_assinados:[],
            web_hook_matriculados:[],
            web_hook_valormatricula:[],
            filtro:{
                tipoeventoaviso:0,
                nome_inscrito:null,
                contatoid:null,
                webhook_id:null,
                sincronizado:null
            },
            url_webhooks:'/api/v1/Crm/getWebHooksUnity/',
            url_search:'/api/v1/Crm/searchWebHooksUnity/',
            complemento_url:"",
        }
    },
    methods:{
        daraHoraBrasileiro(date){
            let dataHora = new Date(date)
            // let dataHoraUtc = new Date()
            return dataHora.toLocaleString('pt-BR', { timezone: 'UTC' })
        }
    },
    created(){
        // this.captados_webhook = this.webHooks;
        // console.log(this.captados_webhook);
        // this.url_webhooks = this.url_webhooks+this.unitie.id
        // this.url_search = this.url_search+this.unitie.id
        
    },
    mounted(){
        // this.getWebHooksUnity();
    }
}
</script>

<style scoped>
    .div-crud-sistema{
            background: white;
            padding: 0px;
            padding-top: 10px;
            border-radius: 0px 0px 10px 10px;
            
    }

    .nav-item{
        
        text-align: center;
        border-radius: 10px 10px 10px 10px!important;
    }

    .tab-pane{
        overflow: auto;
        width: 100%;
    }
    .div-crud-sistema > .col-2 > .nav-pills > .nav-link,
    .div-crud-sistema > .nav > .nav-item > .nav-link{
        color:black!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .div-crud-sistema > .row > .col-2 > .nav-pills > .active,
    .div-crud-sistema > .nav > .nav-item > .active{
        color:white!important;
        background-color:#1c4d5a!important;
        border-radius: 10px 10px 0px 0px!important;
        border: 1px solid #1c4d5a;
        
        height: 100%;
        padding-left: 10px;
    }

    .tab-content > .active {
        background-color:#fff!important;
        border: 1px solid #1c4d5a;
    }

    #td-html > p {
        margin:0px!important;
    }

    .nav-tabs {
        border-bottom: 5px solid #1c4d5a;
    }

    .table thead th {
        vertical-align: bottom;
        border: 0px solid #1c4d5a;
    }

    p{
        margin-bottom:0px;
        max-width:50px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .btn-primary.disabled{
        background-color: #5d7773!important;

        border-color: #5d7773!important;
    }
</style>
